<template>
<div v-if="show&&loginShow">
    <MasterHeader />
    <v-container>
        <h1 class="text-center mb-10 page_title">施設案内リスト 設定</h1>
        <p class="text-center">施設案内のリストを設定できます</p>
        <div class="text-right">
            <v-btn x-large color="blue" dark class="mr-5 mb-3" @click="save()">保存</v-btn>
        </div>
        <div>
            <v-form ref="form">
                <div class="faq_box mx-auto">
                    <h3>【オンシーズン】</h3>
                    <div class="ma-2 pt-3 px-2 border mt-4" v-for="k in count1" :key="k">
                        <p class="text-center">アイテム{{k}}</p>
                        <v-textarea v-model="texts1[k-1]" label="本文*" outlied :rules="requredRules" class="mt-5" auto-grow outlined rows="5" row-height="15" maxlength="255"></v-textarea>
                        <div class="text-right">
                            <v-btn text color="gray" @click="deleted(1,k-1)">削除</v-btn>
                        </div>
                    </div>
                    <v-btn color="red" @click="add(1)" dark class="bold f-12 mt-2">＋</v-btn>
                </div>

                <div class="faq_box mx-auto mt-16">
                      <h3>【設備の概要】</h3>
                    <div class="ma-2 pt-3 px-2 border mt-4" v-for="k in count2" :key="k">
                        <p class="text-center">アイテム{{k}}</p>
                        <v-textarea v-model="texts2[k-1]" label="本文*" outlied :rules="requredRules" class="mt-5" auto-grow outlined rows="5" row-height="15" maxlength="255"></v-textarea>
                        <div class="text-right">
                            <v-btn text color="gray" @click="deleted(2,k-1)">削除</v-btn>
                        </div>
                    </div>
                    <v-btn color="red" @click="add(2)" dark class="bold f-12 mt-2">＋</v-btn>
                </div>

                <div class="faq_box mx-auto mt-16 mb-10">
                        <h3>【利用上の注意】</h3>
                    <div class="ma-2 pt-3 px-2 border mt-4" v-for="k in count3" :key="k">
                        <p class="text-center">アイテム{{k}}</p>
                        <v-textarea v-model="texts3[k-1]" label="本文*" outlied :rules="requredRules" class="mt-5" auto-grow outlined rows="5" row-height="15" maxlength="255"></v-textarea>
                        <div class="text-right">
                            <v-btn text color="gray" @click="deleted(3,k-1)">削除</v-btn>
                        </div>
                    </div>
                    <v-btn color="red" @click="add(3)" dark class="bold f-12 mt-2">＋</v-btn>
                </div>
            </v-form>
        </div>
    </v-container>
</div>
</template>

<script>
import axios from "axios"
import Login from '@/mixins/login'
import MasterHeader from '@/components/MasterHeader.vue'
export default {
    mixins: [Login],
    data() {
        return {
            count1: 0,
            count2: 0,
            count3: 0,
            show: false,
            texts1: [],
            texts2: [],
            texts3: [],
            requredRules: [
                v => !!v || '入力必須です',
            ],
        }
    },
    components: {
        MasterHeader
    },
    async mounted() {

        const response = await axios.post('/master/tearm', {
            replace: "1"
        })
        for (let i = 1; i < 4; i++) {
            this['texts' + i] = response.data['texts' + i];
            this['count' + i] = this['texts' + i].length;
        }
        this.show = true

    },
    methods: {
        async save() {
            this.$refs.form.validate()
            if (!this.$refs.form.validate()) {
                return false
            }
            const data = {
                texts1: JSON.stringify(this.texts1),
                texts2: JSON.stringify(this.texts2),
                texts3: JSON.stringify(this.texts3),
            }

            await axios.post('/master/tearm/save', data)
            window.location.href = "/master/tearm";
        },
        deleted(no,val) {
            this['texts'+no].splice(val, 1)
            this['count' + no] -= 1
        },
        add(val) {
            this['count' + val] += 1
        }
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/master/style.scss";
@import "@/assets/sass/master/faq.scss";
</style>
